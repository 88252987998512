import React, { Suspense, lazy, useRef, useState, useEffect } from "react";
import { Router } from "@reach/router";
import MenuDrawer from "./ui/menu-drawer";
import MenuMobile from "./ui/menu-mobile";
import MenuItem from "./ui/menu-item";
import HomePage from "./ui/home-page";
import Loader from "./ui/loader";
import { useMediaQuery, mediaQueries } from "./ui/util";
import "./App.scss";

const FourOFourPage = lazy(() => import("./ui/fourOfour-page"));
const MenuPage = lazy(() => import("./ui/menu-page"));

const MenuItems = [
  { title: "Maki", url: "maki" },
  { title: "Sushi", url: "sushi" },
  { title: "Lunch", url: "lunch" },
  { title: "Dinner", url: "dinner" },
  { title: "Appetizers", url: "appetizers" },
  { title: "Beverages", url: "beverages" },
];

const App = () => {
  const addMenuTransitionClass = useRef(false);
  const largeMatch = useMediaQuery(mediaQueries.large);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);

  useEffect(() => {
    setMenuOpen(largeMatch);
  }, [largeMatch]);

  const handleMenuClick = (e) => {
    const open = !menuOpen;

    //document.body.style.overflow = open ? "hidden" : null;
    // document.body.style.position = open ? "fixed" : "relative";
    setMenuOpen(open);
  };

  const handleMenuMobileClick = (e) => {
    handleMenuClick();

    if (!addMenuTransitionClass.current) {
      addMenuTransitionClass.current = true;
    }
  };

  return (
    <div className="app-contain">
      <MenuMobile isOpen={menuOpen} onMenuClick={handleMenuMobileClick} />
      {menuOpen && !largeMatch ? (
        <div className="app-tint" onClick={handleMenuClick}></div>
      ) : null}
      <MenuDrawer
        isOpen={menuOpen}
        addTransition={addMenuTransitionClass.current}
        onClickHome={() => setMenuIndex(null)}
      >
        <li className="order-online">
          <a
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Order online"
            href="https://sushizone.menu11.com/livermore/order"
            onClick={() => setMenuIndex(null)}
          >
            Order Online
          </a>
        </li>
        {MenuItems.map((item, index) => (
          <MenuItem
            disabled={!menuOpen}
            active={index === menuIndex}
            ariaLabel={item.title}
            key={index}
            url={item.url}
            onClick={() => {
              if (!largeMatch) {
                handleMenuClick();
              }
              setMenuIndex(index);
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </MenuDrawer>
      <main className="app-contain-main">
        <Suspense fallback={<Loader />}>
          <Router>
            <HomePage path="/" />
            <MenuPage path="maki" disabled={!largeMatch && menuOpen} />
            <MenuPage path="sushi" disabled={!largeMatch && menuOpen} />
            <MenuPage path="lunch" disabled={!largeMatch && menuOpen} />
            <MenuPage path="dinner" disabled={!largeMatch && menuOpen} />
            <MenuPage path="appetizers" disabled={!largeMatch && menuOpen} />
            <MenuPage path="beverages" disabled={!largeMatch && menuOpen} />
            <FourOFourPage default />
          </Router>
        </Suspense>
      </main>
    </div>
  );
};

export default App;
