import React from "react";
import { HOURS } from "../util";

const Hours = () => {
  return (
    <section className="hours-contain">
      <h1 className="hours-banner">Business Hours</h1>
      <div className="hours-contain-block hours-contain--darker">
        <h2>Closed on Monday</h2>
        <hr />
        <h2>Tuesday - Friday</h2>
        <div>
          Lunch <span className="hour-divider">-------</span>{" "}
          {HOURS.tuesday.lunch}
        </div>
        <div>
          Dinner <span className="hour-divider">--------</span>{" "}
          {HOURS.tuesday.dinner}
        </div>
      </div>
      <div className="hours-contain-block hours-contain--dark">
        <h2>Saturday</h2>
        <div>
          Lunch <span className="hour-divider">-----</span>{" "}
          {HOURS.saturday.lunch}
        </div>
        <div>
          Dinner <span className="hour-divider">-----</span>{" "}
          {HOURS.saturday.dinner}
        </div>
        <hr />
        <h2>Sunday</h2>
        <div>
          Dinner <span className="hour-divider">------</span>{" "}
          {HOURS.sunday.dinner}
        </div>
      </div>
      <div className="copyright-contact">
        <p>© Sushizone 2014 - {new Date().getFullYear()} All Rights Reserved</p>
      </div>
    </section>
  );
};

export default Hours;
