import React from "react";
import { Link } from "@reach/router";
import RippleButton from "../ripple-button";

const MenuItem = ({
  disabled = false,
  ariaLabel = "",
  active = false,
  children,
  url = "/",
  onClick = null,
}) => {
  const handleClick = (e) => onClick && onClick(e);

  return (
    <li className={`menu-item${active ? " active" : ""}`}>
      {!disabled ? (
        <Link to={url} aria-label={ariaLabel}>
          <RippleButton
            aria-hidden="true"
            tabIndex={-1}
            disabled={disabled}
            onClick={!disabled ? handleClick : null}
          >
            {children}
          </RippleButton>
        </Link>
      ) : (
        <RippleButton
          aria-hidden="true"
          tabIndex={-1}
          disabled={true}
          onClick={!disabled ? handleClick : null}
        >
          {children}
        </RippleButton>
      )}
    </li>
  );
};

export default MenuItem;
