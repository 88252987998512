import React from "react";

const ResponsiveImage = ({
  className = "",
  src = "",
  srcSet = "",
  alt = ""
}) => {
  return (
    <picture>
      <source type="image/webp" srcSet={srcSet} />
      <img className={className} src={src} alt={alt} />
    </picture>
  );
};

const HeroImage = ({
  className = "",
  src = "",
  srcSetSmall = "",
  srcSetLarge = "",
  alt = ""
}) => {
  return (
    <picture>
      <source
        media="(max-width: 675px)"
        type="image/webp"
        srcSet={srcSetSmall}
      />
      <source
        media="(min-width: 676px)"
        type="image/webp"
        srcSet={srcSetLarge}
      />
      <img className={className} src={src} alt={alt} />
    </picture>
  );
};

export default ResponsiveImage;
export { HeroImage };
