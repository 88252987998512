import React from "react";

const rectElement = ({ className = "", x, y }) => (
  <rect
    className={className}
    x={x}
    y={y}
    rx="12"
    ry="12"
    height="90"
    width="90"
  />
);

/**
 * Credit to: https://codepen.io/thgaskell/pen/KmRjOx
 */
const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-wrapper">
        <h1>Loading...</h1>
        <svg width="100" height="100" viewBox="0 0 300 300">
          <defs>
            <clipPath id="__loader_clip">
              {rectElement({
                className: "square1",
                x: 0,
                y: 0
              })}
              {rectElement({
                className: "square2",
                x: 100,
                y: 0
              })}
              {rectElement({
                className: "square3",
                x: 200,
                y: 0
              })}
              {rectElement({
                className: "square4",
                x: 0,
                y: 100
              })}
              {rectElement({
                className: "square5",
                x: 200,
                y: 100
              })}
              {rectElement({
                className: "square6",
                x: 0,
                y: 200
              })}
              {rectElement({
                className: "square7",
                x: 100,
                y: 200
              })}
            </clipPath>
          </defs>
          <rect
            className="loader-gradient"
            clipPath="url('#__loader_clip')"
            height="300"
            width="300"
          />
        </svg>
      </div>
    </div>
  );
};

export default Loader;
