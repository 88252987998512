import React, { useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Ripple = ({ size, pos }) => {
  const animStyle = {
    height: `${size}px`,
    width: `${size}px`,
    top: `${pos.top}px`,
    left: `${pos.left}px`
  };

  return <span style={animStyle} className="ripple-span" />;
};

const RippleButton = ({ children, ...restProps }) => {
  const rippleContain = useRef();
  const [ripples, setRipple] = useState([]);
  const [isMouseDown, setMouseDown] = useState(false);

  const handleMouseDown = e => {
    if (e.button > 1) {
      return;
    }

    e.stopPropagation();

    const containRect = rippleContain.current.getBoundingClientRect();
    const rippleX = Math.round(e.clientX - containRect.left);
    const rippleY = Math.round(e.clientY - containRect.top);
    const sizeX =
      Math.max(Math.abs(containRect.width - rippleX), rippleX) * 2 + 2;
    const sizeY =
      Math.max(Math.abs(containRect.height - rippleY), rippleY) * 2 + 2;
    const rippleSize = Math.sqrt(Math.pow(sizeX, 2) + Math.pow(sizeY, 2));
    const ripplePos = {
      top: -(rippleSize / 2) + rippleY,
      left: -(rippleSize / 2) + rippleX
    };
    const ripplesCopy = ripples.slice();

    ripplesCopy.push(<Ripple size={rippleSize} pos={ripplePos} />);

    setMouseDown(true);
    setRipple(ripplesCopy);
  };

  const handleMouseUp = e => {
    e.stopPropagation();
    setMouseDown(false);
    setRipple(ripples.slice(1));
  };

  return (
    <button
      onMouseDown={handleMouseDown}
      onMouseUp={isMouseDown ? handleMouseUp : null}
      onMouseLeave={isMouseDown ? handleMouseUp : null}
      {...restProps}
    >
      {children}
      <span ref={rippleContain} className="ripple-contain">
        <TransitionGroup component="span">
          {ripples.map(ripple => (
            <CSSTransition
              in={true}
              key={Date.now()}
              timeout={1000}
              classNames="ripple"
            >
              {ripple}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </span>
    </button>
  );
};

export default RippleButton;
