import React from "react";
import HeroContent from "../hero-content";
import AboutContent from "../about-content";
import HoursContent from "../hours-content";

const HomePage = () => {
  return (
    <div className="home-page">
      <HeroContent />
      <AboutContent />
      <HoursContent />
    </div>
  );
};

export default HomePage;
