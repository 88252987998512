import React, { useEffect, useRef } from "react";
import * as basicScroll from "basicscroll";
import { ArrowDownSVG } from "../svg";
import ResponsiveImage from "../responsive-image";

const Hero = () => {
  const elemRef = useRef();

  useEffect(() => {
    const instance = basicScroll.create({
      elem: elemRef.current,
      from: "top-top",
      to: "bottom-middle",
      props: {
        "--heroOpacity": {
          from: 0.99,
          to: 0.23,
        },
        "--heroFilter": {
          from: 0,
          to: "5px",
        },
      },
    });
    instance.start();

    return () => {
      instance.destroy();
    };
  }, []);

  return (
    <section className="hero-contain">
      <div ref={elemRef} className="hero-background">
        <ResponsiveImage
          className="hero-image"
          srcSet={"/assets/hero/optimized/hero_1024.webp"}
          src={"/assets/hero/hero_1024.jpg"}
          alt="Hero Image"
        />
      </div>
      {/* <a className="hero-arrow" href="#about" aria-label="About Section">
        <ArrowDownSVG />
      </a> */}
    </section>
  );
};

export default Hero;
