import { useState, useEffect } from "react";

export const FUSE_MAP = {
  maki: ["Name", "Inside", "Outside", "Price"],
  sushi: ["Name", "Description", "Price"],
  lunch: ["Name", "Description", "Additional Item(s)", "Item List", "Price"],
  dinner: ["Name", "Description", "Additional Item(s)", "Item List", "Price"],
  appetizers: ["Name", "Description", "Item List", "Price"],
  beverages: ["Name", "Description", "Price"],
};

export const HOURS = {
  monday: {
    lunch: "Closed",
    dinner: "Closed",
  },
  tuesday: {
    lunch: "11:00am - 2:30pm",
    dinner: "5:00pm - 9:00pm",
  },
  wednesday: {
    lunch: "11:00am - 2:30pm",
    dinner: "5:00pm - 9:00pm",
  },
  thursday: {
    lunch: "11:00am - 2:30pm",
    dinner: "5:00pm - 9:00pm",
  },
  friday: {
    lunch: "11:00am - 2:30pm",
    dinner: "5:00pm - 9:00pm",
  },
  saturday: {
    lunch: "11:30am - 2:30pm",
    dinner: "2:30pm - 9:00pm",
  },
  sunday: {
    dinner: "4:30pm - 9:00pm",
  },
};

export const mediaQueries = {
  small: "(min-width: 0)",
  medium: "(min-width: 676px)",
  large: "(min-width: 900px)",
};

export const parseDate = () => {
  const dateObj = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const day = new Date(dateObj).getDay();
  const secondSlashIndex = dateObj
    .toString()
    .indexOf("/", dateObj.toString().indexOf("/") + 1);

  return {
    day,
    date: dateObj.toString().slice(0, secondSlashIndex),
  };
};

export const getHours = (day) => {
  switch (day) {
    case 1: {
      return {
        lunch: HOURS.monday.lunch,
        dinner: HOURS.monday.dinner,
      };
    }
    case 2:
    case 3:
    case 4: {
      return {
        lunch: HOURS.tuesday.lunch,
        dinner: HOURS.tuesday.dinner,
      };
    }
    case 5: {
      return {
        lunch: HOURS.friday.lunch,
        dinner: HOURS.friday.dinner,
      };
    }
    case 6: {
      return {
        lunch: HOURS.saturday.lunch,
        dinner: HOURS.saturday.dinner,
      };
    }
    case 0: {
      return {
        dinner: HOURS.sunday.dinner,
      };
    }
    default:
      break;
  }
};

export const useMediaQuery = (query) => {
  const [match, setMatch] = useState(false);
  useEffect(() => {
    const queryList = matchMedia(query);

    const queryHandler = () => {
      setMatch(queryList.matches);
    };

    queryHandler();
    queryList.addListener(queryHandler);
  }, [query]);

  return match;
};

export const reduceCategory = (categories, items) => {
  return categories.reduce((accum, currentValue) => {
    items[currentValue].forEach((item) => {
      accum.push(item);
    });

    return accum;
  }, []);
};
