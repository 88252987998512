/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Transition } from "react-transition-group";

const transitionStyles = {
  left: {
    entering: {},
    entered: {},
    exiting: {},
    exited: {
      transform: "translate(-101%, 0)"
    }
  },
  right: {
    entering: {},
    entered: {},
    exiting: {},
    exited: {
      transform: "translate(101%, 0)"
    }
  },
  top: {
    entering: {},
    entered: {},
    exiting: {},
    exited: {
      transform: "translate(0, -101%)"
    }
  },
  bottom: {
    entering: {},
    entered: {},
    exiting: {},
    exited: {
      transform: "translate(0, 101%)"
    }
  }
};

const Drawer = ({ children, isOpen, anchor, ...restProps }) => (
  <Transition in={isOpen} timeout={isOpen ? 1000 : 0}>
    {state => (
      <div css={[transitionStyles[anchor][state]]} {...restProps}>
        {children}
      </div>
    )}
  </Transition>
);

Drawer.defaultProps = {
  anchor: "left",
  isOpen: false
};

export default Drawer;
