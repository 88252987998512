import React, { useEffect, useRef } from "react";
import * as basicScroll from "basicscroll";
import ResponsiveImage from "../responsive-image";

const About = () => {
  const headerRef = useRef();
  const imgRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    const headerInstance = basicScroll.create({
      elem: headerRef.current,
      from: "middle-bottom",
      to: "top-middle",
      props: {
        "--aboutHeaderOpacity": {
          from: 0.35,
          to: 0.99,
        },
      },
    });

    const imgInstance = basicScroll.create({
      elem: imgRef.current,
      from: "top-bottom",
      to: "top-middle",
      props: {
        "--aboutImgOpacity": {
          from: 0.01,
          to: 0.99,
        },
      },
    });

    const textInstance = basicScroll.create({
      elem: textRef.current,
      from: "top-bottom",
      to: "top-middle",
      props: {
        "--aboutTxtOpacity": {
          from: 0.01,
          to: 0.99,
        },
      },
    });

    headerInstance.start();
    imgInstance.start();
    textInstance.start();

    return () => {
      headerInstance.destroy();
      imgInstance.destroy();
      textInstance.destroy();
    };
  }, []);

  return (
    <section className="about-contain" id="about">
      <h1 ref={headerRef}>About Sushizone</h1>
      <div className="about-contain-content">
        <div ref={imgRef} className="about-contain-img">
          <ResponsiveImage
            src={"/assets/home/storeFront_676.jpg"}
            srcSet={"/assets/home/optimized/storeFront_676.webp"}
            alt="Sushi Zone Location"
          />
        </div>
        <div ref={textRef} className="about-contain-info">
          <p>
            Sushi Zone first opened in 2010 by Chef/owner, Sean. The cozy
            restaurant has a great selection of fresh fish and feature many
            combinations of fresh ingredients and delicacies that go great with
            one of our many sake's. Sushi Zone is open Tuesday thru Sunday
            (closed on Mondays). We hope to see you soon!
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
