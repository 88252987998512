import React from "react";
import { Link } from "@reach/router";
import ResponsiveImage from "../responsive-image";
import RippleButton from "../ripple-button";
import { MenuSVG } from "../svg";
import { useMediaQuery, mediaQueries } from "../util";

const MenuMobile = ({ onMenuClick = null, isOpen = false }) => {
  const largeMatch = useMediaQuery(mediaQueries.large);

  if (largeMatch) {
    return null;
  }

  return (
    <header className="menu-mobile">
      <RippleButton onClick={onMenuClick} aria-label="Menu Navigation">
        <MenuSVG />
      </RippleButton>
      <Link to="/" onClick={isOpen ? onMenuClick : null}>
        <h1>
          <ResponsiveImage
            src={"/assets/home/sushizone_logo.jpg"}
            srcSet={"/assets/home/optimized/sushizone_logo.webp"}
            alt="Sushi Zone Logo"
          />
        </h1>
      </Link>
    </header>
  );
};

export default MenuMobile;
